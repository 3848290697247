@use '../node_modules/slick-carousel/slick/slick.scss';
@use '../node_modules/slick-carousel/slick/slick-theme.scss';
@use '../node_modules/animate.css/animate.min.css';
@use '../node_modules//react-toastify/dist/ReactToastify.css';
@use './assets/scss/config' as *;

body {
  margin: 0;
  padding: 0;
}

.slick-slide img {
  margin: auto;

  @include respond(tablet) {
    max-width: 18.75rem;
  }
}

.backToTop {
  position: fixed;
  bottom: 100px;
  right: 100px;
  z-index: 1;
  cursor: pointer;

  @include respond(tablet) {
    display: none;
  }

  .triangle {
    @include triangle(2rem);
  }
}

// Fonts
@font-face {
  font-family: 'AeroportLight';
  src: local('AeroportLight'), url('./assets/fonts/Aeroport-Light.otf') format('opentype');
}

@font-face {
  font-family: 'AeroportRegular';
  src: local('AeroportRegular'), url('./assets/fonts/Aeroport-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'AeroportMedium';
  src: local('AeroportMedium'), url('./assets/fonts//Aeroport-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'AeroportBold';
  src: local('AeroportBold'), url('./assets/fonts/Aeroport-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'TelecomLight';
  src: local('TelecomLight'), url('./assets/fonts/Telecom-Light.ttf') format('truetype');
}
