@use '../../../assets/scss/config' as *;

$duration: 0.4s;
$toggled-size: 1;

header {
  display: grid;
  grid-template-rows: auto auto;
  position: fixed;
  width: 100%;
  background-color: $primary-background-color;
  color: $primary-font-color;
  z-index: 100;
}

.container {
  // display: flex;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: 120px auto;
  align-items: center;

  .logo {
    font-family: TelecomLight;
    text-transform: uppercase;
    padding: 1.5rem;
    font-size: 2rem;
    line-height: 2rem;
    border-right: 1px solid #151617;
  }
}

.navIcon {
  padding: 1.5rem;
  justify-self: end;
}

.bar1 {
  width: 20px;
  height: 2px;
  background-color: $primary-font-color;
  margin: 0.3rem 0;
  transition: 0.4s;

  &.change {
    -webkit-transform: rotate(-45deg) translate(0, 5px);
    transform: rotate(-45deg) translate(0, 5px);
  }
}

.bar2 {
  width: 20px;
  height: 2px;
  background-color: $primary-font-color;
  margin: 0.3rem 0;
  transition: 0.4s;

  &.change {
    -webkit-transform: rotate(45deg) translate(1px, -5px);
    transform: rotate(45deg) translate(1px, -5px);
  }
}

nav {
  clear: both;
  padding: 0;
  background: $primary-background-color;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      text-align: center;
      margin: 0;
      padding: 0;

      a {
        display: block;
        padding: 0.8rem 0;
        font-size: 1rem;
        text-decoration: none;
        font-weight: 500;
        text-transform: uppercase;
        color: $primary-font-color;
        letter-spacing: 0.1rem;
        border-top: 1px solid #151617;
        font-family: TelecomLight;
      }
    }
  }
}
