@use '../../assets/scss/config' as *;

.reservation {
  background-color: $secondary-font-color;
  color: $primary-font-color;
  min-height: 80vh;
  display: grid;
  grid-template-columns: 28% auto 20%;
  grid-template-rows: 35% auto;
  overflow-x: hidden;

  @include respond(large) {
    grid-template-rows: repeat(2, 1fr);
  }

  @include respond(tablet) {
    grid-template-columns: 15% 85%;
    grid-template-rows: auto;
    min-height: 50vh;
  }

  .number {
    grid-column: 1;
    grid-row: 1;
    border-right: 1px solid #151617;
    border-bottom: 1px solid #151617;
    display: flex;
    justify-content: flex-end;
    padding: 7rem 4rem 4rem 4rem;
    margin: 0;
    font-size: 1.5rem;
    color: #7a786c;
    font-family: AeroportRegular;

    @include respond(large) {
      align-items: flex-start;
    }

    @include respond(tablet) {
      border: none;
      padding-top: 2rem;
      font-size: 0.625rem;
      justify-self: center;
    }
  }

  .circle {
    grid-column: 1;
    grid-row: 2;
    border-right: 1px solid #151617;
    position: relative;
    overflow-y: hidden;

    @include respond(tablet) {
      display: none;
    }

    .draw {
      position: absolute;
      display: inline-block;
      width: 130%;
      height: 130%;
      border-radius: 50%;
      border-style: solid;
      border-width: 1px;
      border-color: #151617;
      right: 0;
    }
  }

  .form {
    grid-row: 1 / 3;
    padding: 6.5rem 8rem 6.5rem 6.5rem;

    @include respond(tablet) {
      padding: 2rem 3rem 2rem 2rem;
    }

    .inlineInput {
      display: flex;

      @include respond(tablet) {
        display: inline;
      }
    }

    .title {
      text-transform: uppercase;
      color: #2f3d42;
      font-size: 5.25rem;
      font-family: TelecomLight;

      @include respond(tablet) {
        font-size: 1.875rem;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .reservationForm {
      padding-top: 1.6rem;
    }

    .contact {
      padding-top: 1.5rem;
      width: 70%;
      font-size: 1.375rem;
      color: #072323;
      font-family: AeroportLight;
      opacity: 0.57;

      @include respond(tablet) {
        font-size: 0.5rem;
        width: 100%;
        padding-top: 1rem;
      }
    }
  }

  .dining {
    grid-row: 1 / 3;
    position: relative;
    // overflow-x: hidden;

    @include respond(tablet) {
      display: none;
    }

    img {
      position: absolute;
      min-height: 80%;

      top: 0;
      bottom: 0;
      margin: auto;

      width: 340px;
      height: 525px;
      border-top-left-radius: 50% 10em;
      border-top-right-radius: 50% 10em;
      transform: translateX(30%);
    }
  }
}
