@use '../../assets/scss/config' as *;

.menu {
  background-color: $menu-background-color;
  color: $primary-font-color;
  min-height: 100vh;

  .header {
    display: grid;
    grid-template-columns: 28% 56% 16%;
    grid-template-rows: 18.75rem;

    @include respond(large) {
      grid-template-columns: 20% 64% 16%;
    }

    @include respond(tablet) {
      grid-template-columns: 120px auto;
      grid-template-rows: auto;
    }

    .headerIcon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 2rem;

      @include respond(tablet) {
        justify-content: center;
      }

      .triangle {
        @include triangle(3rem);

        @include respond(tablet) {
          @include triangle(1rem);
        }
      }
    }

    .headerDescription {
      border-right: 1px solid #151617;
      color: $custom-font-color;
      font-size: 0.875rem;
      font-family: AeroportRegular;
      padding: 0 50% 0 7.5rem;
      opacity: 0.68;
      display: flex;
      align-items: center;

      @include respond(tablet) {
        font-size: 0.625rem;
        padding: 1.625rem;
      }
    }
  }

  .menuContent {
    display: grid;
    grid-template-columns: 28% 56% 16%;
    grid-template-rows: auto auto;

    @include respond(tablet) {
      grid-template-columns: 15% 85%;
    }

    .menuNumber {
      text-align: right;
      border-top: 1px solid #151617;
      font-family: AeroportRegular;
      font-size: 1.44rem;
      color: #7a786c;
      padding: 5rem 3.5rem 7.5rem 7.5rem;
      line-height: 30px;

      @include respond(tablet) {
        grid-row: 1 / 3;
        padding: 2rem;
        font-size: 0.625rem;
        line-height: 15px;
      }
    }

    .menuDescription {
      border-top: 1px solid #151617;
      border-right: 1px solid #151617;
      padding: 5rem 25% 0 7.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include respond(tablet) {
        padding: 2rem 3rem 0 2rem;
      }

      p {
        line-height: 6rem;
        font-family: TelecomLight;
        text-transform: uppercase;
        font-size: 6rem;
        color: $primary-font-color;
        margin: 0;

        @include respond(tablet) {
          font-size: 1.875rem;
          line-height: 2rem;
          padding-bottom: 1rem;
        }
      }

      img {
        max-width: 100%;
        max-height: 100%;
        opacity: 0.7;
      }
    }

    .menuSide {
      padding: 0 3.5rem 0 35%;
      text-align: right;

      @include respond(tablet) {
        display: none;
      }

      p {
        color: $custom-font-color;
        font-size: 0.875rem;
        font-family: AeroportRegular;
        opacity: 0.68;
        margin: 0;
      }

      .sideImage {
        margin-top: 20px;
        display: grid;
        grid-template-columns: auto 1px;
        align-items: center;

        .hrContainer {
          position: relative;

          hr {
            position: absolute;
            left: 2rem;
            background-color: #151617;
            height: 0.75px;
            border: 0;
            width: 100px;
          }
        }

        img {
          max-width: 55%;
          margin-left: auto;
        }
      }
    }

    .menuDetails {
      border-right: 1px solid #151617;
      padding: 5rem 20% 6.5rem 7.5rem;

      @include respond(large) {
        padding: 5rem 10% 7rem 7.5rem;
      }

      @include respond(tablet) {
        padding: 0 3rem 2.4rem 2rem;
      }

      .menuItem {
        padding-bottom: 1rem;
        border-bottom: 0.75px solid #151617;
        padding-top: 2rem;

        .title {
          color: $primary-font-color;
          font-size: 3.25rem;
          font-family: TelecomLight;
          text-transform: uppercase;
          -webkit-tap-highlight-color: transparent;

          @include respond(tablet) {
            font-size: 1.8rem;
          }
        }

        .download {
          cursor: pointer;
          text-decoration: none;
          font-family: AeroportRegular;
          color: $custom-font-color;

          @include respond(tablet) {
            font-size: 1rem;
            padding-bottom: 0.5rem;
          }
        }
      }
    }

    .menuOverview {
      grid-column: 3;
      grid-row: 1 / 3;
      color: #2f3330;
      font-size: 9rem;
      text-transform: uppercase;
      border-bottom: 1px solid #151617;
      transform: rotate(180deg);
      writing-mode: vertical-rl;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2.5rem 0;

      @include respond(large) {
        font-size: 7rem;
      }

      @include respond(tablet) {
        display: none;
      }
    }
  }
}
