@use '../../../assets/scss/config' as *;

.home {
  display: grid;
  grid-template-columns: 120px auto;
  grid-template-rows: 80px auto 200px;
  background-color: $primary-background-color;
  color: $primary-font-color;
  min-height: 100vh;

  .headerEmptyContainer {
    grid-column: 1 / 3;
  }

  .lift {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 75% 25%;
    border-right: 1px solid #151617;
    border-bottom: 1px solid #151617;

    .triangleContainer {
      margin: 0.5rem 0 0 0.6rem;
      align-self: flex-end;
      padding: 3.1rem 0 0 2.25rem;
      margin-bottom: 5.5rem;

      .triangleUp {
        @include triangle(1rem);
      }

      .triangleDown {
        @include triangle(1rem);
        margin-top: 1.5rem;
      }
    }

    .elevateSenses {
      color: $secondary-font-color;
      text-transform: uppercase;
      font-family: AeroportLight;
      font-size: 0.63rem;
      opacity: 0.6;
      justify-self: end;
      transform: rotate(180deg);
      writing-mode: vertical-rl;
      margin-right: 0.31rem;
      margin-bottom: 5rem;
    }

    .flavour {
      position: relative;
      grid-column-start: 1;
      grid-column-end: 3;
      padding: 2.25em;
      z-index: 1;

      p {
        margin-top: -2.35rem;
        position: absolute;
        top: 0;
        width: 250%;
        display: inline;
        font-size: 2.5rem;
        text-transform: uppercase;
        font-family: TelecomLight;
      }
    }
  }

  .nextLevel {
    border-bottom: 1px solid #151617;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
      padding-bottom: 5rem;
      max-height: 76%;
      border-top-left-radius: 50% 10em;
      border-top-right-radius: 50% 10em;
      transform: translateX(8%);
      width: 364px;
      height: auto;
    }
  }

  .meat {
    border-right: 1px solid #151617;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .degustationMenu {
    padding: 2rem;

    .upcomingEvent {
      font-size: 10;
      text-transform: uppercase;
      font-family: AeroportLight;
      letter-spacing: 0.35px;
    }

    .title {
      font-size: 1.3rem;
      text-transform: uppercase;
      letter-spacing: 2.44px;
      font-family: TelecomLight;
      color: $custom-font-color;
      margin: 1rem 0;
    }

    a {
      display: inline;
      text-transform: uppercase;
      font-size: 0.75rem;
      color: $custom-font-color-1;
      border-bottom: 3px solid $custom-font-color-1;
      padding: 0.25rem 0;
      text-decoration: none;
    }
  }
}

@media (max-width: 768px) {
  .nextLevel img {
    transform: translateX(25%) !important;
  }
}
