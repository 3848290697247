@use '../../assets/scss/config' as *;

.footer {
  min-height: 20vh;
  display: grid;
  grid-template-columns: 30% 70%;
  background-color: $menu-background-color;
  align-items: center;
  justify-items: center;

  @include respond(tablet) {
    min-height: 10vh;
  }

  .logo {
    font-family: TelecomLight;
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 4rem;
    color: $primary-font-color;

    @include respond(tablet) {
      font-size: 1.8rem;
      line-height: 1.8rem;
    }
  }

  .menu {
    // @include respond(tablet) {
    //   justify-self: center;
    // }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      line-height: 1rem;

      li {
        display: inline;
        padding-right: 5rem;

        @include respond(tablet) {
          padding-right: 1rem;
        }

        a {
          text-decoration: none;
          font-family: AeroportLight;
          font-size: 1.6rem;
          color: $primary-font-color;
          text-transform: uppercase;

          @include respond(tablet) {
            font-size: 0.5rem;
          }
        }

        a:hover {
          cursor: pointer;
        }
      }
    }
  }
}
