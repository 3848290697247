@mixin respond($breakpoint) {
  @if ($breakpoint == large) {
    @media (max-width: 1540px) {
      @content;
    }
  }

  @if ($breakpoint == medium) {
    @media (max-width: 1024px) {
      @content;
    }
  }

  @if ($breakpoint == tablet) {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if ($breakpoint == phone) {
    @media (max-width: 576px) {
      @content;
    }
  }

  // Custom break points
  @if ($breakpoint == greaterThanTablet) {
    @media (min-width: 769px) {
      @content;
    }
  }
}
