@use '../../assets/scss/config' as *;

$custom-padding: 2.8rem;

.home {
  display: grid;
  grid-template-columns: 28% 56% 16%;
  background-color: $primary-background-color;
  color: $primary-font-color;
  min-height: 100vh;
  position: relative;

  @include respond(tablet) {
    display: none;
  }

  .logo {
    font-family: TelecomLight;
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 4rem;
  }

  .lift {
    padding: $custom-padding;
    border-right: 1px solid #151617;
    display: grid;
    grid-template-rows: 1fr 1fr;

    .navigationMenu {
      display: flex;
      align-items: flex-end;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        width: 100%;

        li {
          margin-bottom: 1.8rem;

          a {
            letter-spacing: 2.5px;
            color: #949382;
            font-family: TelecomLight;
            font-size: 2rem;
            text-decoration: none;
            width: 100%;
            display: flex;
            align-items: center;
            text-transform: uppercase;

            img {
              display: none;
              width: 0.8rem;
              height: 0.8rem;
              transform: rotate(90deg);
              margin-left: 1.25rem;
              margin-bottom: 0.3rem;
            }
          }
        }

        li a:hover {
          cursor: pointer;

          img {
            display: inline;
          }
        }
      }
    }
  }

  .nextLevel {
    padding: $custom-padding 0 0 $custom-padding;
    border-right: 1px solid #151617;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 15% 50% 35%;
    grid-template-areas:
      'up senses'
      'flavour flavour'
      '. dining';

    .triangleContainer {
      margin: 0.5rem 0 0 0.6rem;

      .triangleUp {
        @include triangle(1.5rem);
      }

      .triangleDown {
        @include triangle(1.5rem);
        margin-top: 2rem;
      }
    }

    .elevateSenses {
      grid-area: senses;
      padding-left: $custom-padding;

      p {
        color: $secondary-font-color;
        text-transform: uppercase;
        font-family: AeroportLight;
        font-size: 1rem;
        opacity: 0.6;
        margin: 0;
      }
    }

    .flavour {
      grid-area: flavour;
      padding-right: 4rem;

      @include respond(large) {
        padding-right: 3rem;
      }

      p {
        font-size: 7.5rem;
        text-transform: uppercase;
        font-family: TelecomLight;
        margin: 0;

        @include respond(large) {
          margin-top: 1.5rem;
          font-size: 5.5rem;
        }
      }
    }

    .diningTable {
      grid-area: dining;
      position: relative;

      img {
        margin: auto;
        position: absolute;
        width: 350px;
        bottom: 0;
        left: 0;
        right: 0;
        border-top-left-radius: 50% 10em;
        border-top-right-radius: 50% 10em;

        @include respond(large) {
          height: auto;
        }
      }
    }
  }

  .degustationMenu {
    padding: 0 2rem 2rem 2rem;

    img {
      margin-top: 12.5rem;
      margin-bottom: 2.8rem;
      max-width: 100%;
      height: auto;
      width: auto;

      @include respond(large) {
        margin-top: $custom-padding;
      }
    }

    .upcomingEvent {
      font-size: 1rem;
      text-transform: uppercase;
      font-family: AeroportLight;
      letter-spacing: 0.35px;
    }

    .title {
      font-size: 2rem;
      text-transform: uppercase;
      letter-spacing: 2.44px;
      font-family: TelecomLight;
      color: $custom-font-color;
      margin: 1rem 0;

      @include respond(large) {
        font-size: 1.25rem;
      }
    }

    .description {
      font-size: 0.8rem;
      font-family: AeroportRegular;
      color: $custom-font-color;
      margin-bottom: 3.1rem;
    }

    a {
      display: inline;
      text-transform: uppercase;
      font-size: 25px;
      color: $custom-font-color-1;
      border-bottom: 3px solid $custom-font-color-1;
      font-family: AeroportLight;
      padding: 4px 0;
      text-decoration: none;
    }

    a:hover {
      cursor: pointer;
    }
  }

  .separator {
    height: 100%;
    width: 1px;
    background-color: #151617;
    position: absolute;
    top: 0;
    left: calc(56% + 1.4rem);
  }
}

.mobile {
  // overflow-x: hidden;

  @include respond(greaterThanTablet) {
    display: none;
  }
}
