@mixin triangle($size: 2rem, $background: $primary-font-color) {
  & {
    position: relative;
    background-color: $background;
    text-align: left;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: inherit;
  }

  &,
  &::before,
  &::after {
    width: $size;
    height: $size;
    border-top-right-radius: 30%;
  }

  & {
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  }

  &::before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }

  &::after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
}
