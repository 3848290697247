@use '../../assets/scss/config' as *;

.deliveryBox {
  display: grid;
  grid-template-columns: 28% auto 20%;
  min-height: 75vh;
  background-color: $secondary-background-color;
  background-image: url('../../assets/images/delivery_box.png');
  background-size: cover;
  background-repeat: no-repeat;

  @include respond(tablet) {
    display: none;
  }

  .wine {
    .number {
      display: flex;
      justify-content: flex-end;
      padding: 7rem 4rem 4rem 4rem;
      font-size: 1.5rem;
      color: $secondary-font-color;
      font-family: AeroportRegular;
    }

    .wineContainer {
      display: flex;
      justify-content: flex-end;
      padding-right: 2rem;

      img {
        width: 252px;
        height: 300px;
      }
    }
  }

  .box {
    padding: 6.5rem 8rem 6.5rem 6.5rem;

    .title {
      font-family: TelecomLight;
      font-size: 5.25rem;
      color: $secondary-font-color;
      text-transform: uppercase;
      width: 75%;

      @include respond(large) {
        width: 100%;
        font-size: 4rem;
      }
    }

    .description {
      color: #3a403c;
      font-size: 1.5rem;
      font-family: AeroportLight;
    }

    .orderNow {
      color: $secondary-font-color;
      font-size: 1.75rem;
      text-transform: uppercase;
      font-family: AeroportMedium;
      width: 25%;
      padding-top: 1rem;

      @include respond(large) {
        width: 100%;
        font-size: 1.5rem;
      }
    }
  }

  .customShape {
    position: relative;
    overflow-x: hidden;

    .triangleContainer {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 7rem 0;

      .triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 250px 500px 250px;
        border-color: transparent transparent #151617 transparent;
        position: relative;

        @include respond(large) {
          border-width: 0 200px 400px 200px;
        }
      }

      .triangle:after {
        content: '';
        border-style: solid;
        border-width: 0 249px 498px 249px;
        border-color: transparent transparent $secondary-background-color;
        position: absolute;
        top: 1px;
        left: -249px;

        @include respond(large) {
          border-width: 0 199px 398px 199px;
          top: 1px;
          left: -199px;
        }
      }
    }
  }
}

.mobile {
  @include respond(greaterThanTablet) {
    display: none;
  }
}
