@use '../../../assets/scss/config' as *;

.deliveryBox {
  display: grid;
  grid-template-columns: 15% 85%;
  grid-template-rows: repeat(2, 1fr);
  min-height: 75vh;
  background-color: $secondary-background-color;
  overflow: hidden;

  .header {
    grid-column: 1 / 3;
    background-image: url('../../../assets/images/delivery_box_mobile.png');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 6.3rem 0 0 3.5rem;
    z-index: 2;

    img {
      max-width: 40%;
    }
  }

  .number {
    justify-self: center;
    font-size: 0.625rem;
    font-family: AeroportRegular;
    color: $secondary-font-color;
    padding-top: 2rem;
  }

  .box {
    padding: 2rem 3rem 2rem 2rem;
    z-index: 2;

    .title {
      color: $secondary-font-color;
      font-family: TelecomLight;
      font-size: 1.875rem;
      text-transform: uppercase;
      width: 80%;
    }

    .description {
      font-size: 0.75rem;
      font-family: AeroportLight;
      color: $menu-background-color;
      padding: 1rem 0;
    }

    .orderNow {
      color: $secondary-font-color;
      font-family: AeroportMedium;
      text-transform: uppercase;
      width: 50%;
      font-size: 0.75rem;
    }
  }

  .customShape {
    position: relative;

    .triangleContainer {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0 0 15.5rem 12rem;

      .triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 150px 300px 150px;
        border-color: transparent transparent #151617 transparent;
        position: relative;
      }

      .triangle:after {
        content: '';
        border-style: solid;
        border-width: 0 149px 298px 149px;
        border-color: transparent transparent $secondary-background-color;
        position: absolute;
        top: 1px;
        left: -149px;
      }
    }
  }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
  .deliveryBox {
    .box {
      .title {
        font-size: 3rem;
        width: 60%;
      }

      .description {
        font-size: 1.2rem;
      }

      .orderNow {
        font-size: 1.5rem;
      }
    }

    .customShape {
      .triangleContainer {
        padding: 0 0 21rem 33rem;
        color: red;
        .triangle {
          border-width: 0 200px 400px 200px;
        }

        .triangle:after {
          border-width: 0 199px 398px 199px;
          top: 1px;
          left: -199px;
        }
      }
    }
  }
}
