@use '../../assets/scss/config' as *;

.imageGallery {
  // min-height: 50vh;

  .container {
    // padding: 1rem;
    background: $menu-background-color;
  }
}
