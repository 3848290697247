// Background colors
$primary-background-color: #2f3330;
$menu-background-color: #3a403c;
$secondary-background-color: #995a3f;

// Font color
$primary-font-color: #b29782;
$secondary-font-color: #c1c4b7;
$custom-font-color: #949382;
$custom-font-color-1: #b87255;

// Border colors
$primary-border-color: #707070;
